import React, { useEffect, useState, createContext } from 'react';
import { NameItem } from '../types/certificate';

interface DeleteListContextValue {
  names: NameItem[];
  add: (domain: string, arn: string) => void;
  remove: (domain: string) => void;
  clear: () => void;
}

const defaultDeleteListContext: DeleteListContextValue = {
  names: [],
  add: () => {},
  remove: () => {},
  clear: () => {},
};

export const DeleteListContext = createContext<DeleteListContextValue>(defaultDeleteListContext);

const DeleteListContextProvider: React.FC = ({ children }) => {
  const [state, updateState] = useState<DeleteListContextValue>(defaultDeleteListContext);

  const add = (domain: string, arn: string) => {
    updateState((s) => {
      return {
        ...s,
        names: [...s.names, { domain, arn }],
      };
    });
  };

  const remove = (domain: string) => {
    updateState((s) => {
      return {
        ...s,
        names: s.names.filter((i) => i.domain !== domain),
      };
    });
  };

  const clear = () => {
    updateState((s) => ({ ...s, names: [] }));
  };

  useEffect(() => {
    updateState((s) => ({ ...s, add, remove, clear }));
  }, []);

  return <DeleteListContext.Provider value={state}>{children}</DeleteListContext.Provider>;
};

export default DeleteListContextProvider;
