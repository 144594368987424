import React, { useContext, useEffect } from 'react';
import { useState } from 'react';
import { Link, RouteComponentProps, useHistory, useLocation, withRouter } from 'react-router-dom';
import Button from '../components/button';
import TextInput from '../components/input';
import LoadingDots from '../components/loading-dots';
import Notice from '../components/notice';
import Paper from '../components/paper';
import SelectOnClick from '../components/select-on-click';
import { AuthContext } from '../context/auth-provider';
import { DeleteListContext } from '../context/delete-list';
import { Certificate } from '../types/certificate';

// URL variables
interface Params {
  q?: string;
}

interface Search {
  results: Certificate[];
  status: number;
  query: string;
  error?: string;
  nextKey?: string;
  validDomain: boolean;
}

interface Res {
  DomainNames: Certificate[] | null;
  LastEvaluatedKey?: string;
  QValidDomain: boolean;
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const DomainSearchRoute: React.FC<RouteComponentProps<Params>> = ({ match }) => {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const deleteList = useContext(DeleteListContext);
  const queryParmas = useQuery();

  const q = queryParmas.get('q');

  const [query, updateQuery] = useState(q ? decodeURIComponent(q) : '');
  const [search, updateSearch] = useState<Search | null>(null);
  const [loading, updateLoading] = useState<boolean>(false);

  const getSearchResults = async (q: string) => {
    try {
      const res = await auth.fetch(`/api/names?q=${q}`);

      if (res.ok && res.status === 200) {
        const json: Res = await res.json();

        const newSearch: Search = {
          status: res.status,
          results: json.DomainNames || [],
          query,
          validDomain: json.QValidDomain,
        };

        if (json.LastEvaluatedKey) {
          newSearch.nextKey = json.LastEvaluatedKey;
        }

        updateSearch(newSearch);
      } else {
        updateSearch({
          error: 'Error fetching search results.',
          status: res.status,
          results: [],
          query,
          validDomain: false,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onSubmit = async (e: React.FormEvent | undefined) => {
    if (e) {
      e.preventDefault();
    }

    updateSearch(null);

    // Update the URL
    history.push(`/names?q=${encodeURIComponent(query)}`);

    // Make the request
    getSearchResults(query);
  };

  const onNewDomain = async () => {
    updateLoading(true);
    // return;
    try {
      const res = await auth.fetch(`/api/names?name=${query}`, { method: 'post' });

      if (res.ok && res.status === 200) {
        const json = await res.json();
        history.push(`/certificates/${json.Response.CertificateArn}?new&q=${q}`);
      } else {
        alert(`Error adding "${query}" as a domain. Server responded with status ${res.status}`);
        updateLoading(false);
      }
    } catch (e) {
      alert(`Error adding new domain, could not make domain request.`);
      updateLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    if (query !== '') {
      getSearchResults(query);
    }
  }, []);

  return (
    <div className="route space-items-y-lg">
      <Paper>
        <p className="mb-2">Domain search</p>
        <form className={`space-items-y ${loading ? 'loading' : ''}`} onSubmit={onSubmit}>
          <TextInput
            variant="contained"
            id="domain-search-input"
            label="Domain / ARN"
            autoFocus
            value={query}
            onChange={(e) => {
              e.preventDefault();
              // @ts-ignore
              updateQuery(e.target.value);
            }}
          />
          <div className="flex justify-end items-center space-items-x">
            <Button
              disabled={search === null || search?.validDomain == false}
              onClick={(e) => {
                e.preventDefault();
                onNewDomain();
              }}
            >
              Add Domain
            </Button>
            <Button variant="contained" type="submit">
              Search
            </Button>
          </div>
        </form>
      </Paper>

      <Paper>
        <p className="mb-5 text-gray-400">Search results</p>
        {(() => {
          // If query is empty show a helper message
          if (query === '' && search?.status !== 200) {
            return <p className="text-center py-10">Enter search terms.</p>;
          }
          // Search is not set / loading results
          else if (search === null) {
            return (
              <div className="p-10">
                <LoadingDots />
              </div>
            );
          }
          // Error
          else if (search.status !== 200) {
            return (
              <div>
                {search.error ? (
                  <Notice
                    message={[search.error, `Status code: ${search.status}`, '', 'Check the JavaScript console for more details']}
                    type="danger"
                  />
                ) : null}
              </div>
            );
          }
          // Success
          else {
            // TODO need to see final data structure
            if (search.results.length === 0) {
              return <p>No domains match this search.</p>;
            }

            return (
              <div className="space-items-y-lg">
                {search.results.map((cert) => {
                  let color = 'text-red-500';

                  switch (cert.Status) {
                    case 'ISSUED':
                      color = 'text-green-500';
                      break;

                    case 'ISSUED':
                    case 'PENDING_VALIDATION':
                      color = 'text-yellow-500';
                      break;
                  }

                  const inDeleteQueue = !(deleteList.names.find((d) => d.domain === cert.DomainName) === undefined);

                  return (
                    <div key={`${cert.DomainName}-${cert.CertificateArn}`} className="name-search-item flex justify-between items-center">
                      <div>
                        <Link to={`/certificates/${cert.CertificateArn}?q=${query}`} className="font-bold">
                          {cert.DomainName}
                        </Link>
                        <SelectOnClick text={cert.CertificateArn} variant="subtitle" />
                      </div>

                      <div>
                        <div className="flex justify-center items-center space-items-x">
                          <p className={`name-search-status ${color}`}>{cert.Status}</p>
                          <Button
                            // variant={inDeleteQueue ? 'outlined' : 'text'}
                            variant="outlined"
                            className="name-search-item-delete-button"
                            color={inDeleteQueue ? 'danger' : 'default'}
                            onClick={() => {
                              if (inDeleteQueue) {
                                deleteList.remove(cert.DomainName);
                              } else {
                                deleteList.add(cert.DomainName, cert.CertificateArn);
                              }
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {search.nextKey ? <p className="pt-10 pb-10 text-center">More results match this search. Refine your search if needed.</p> : null}
              </div>
            );
          }
        })()}
      </Paper>
    </div>
  );
};

export default withRouter(DomainSearchRoute);
