import React, { useState, useEffect, createContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import auth from '../auth';
import AuthLoadingRoute from '../routes/auth-loading';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

interface AuthContextValue {
  username: string;
  logout: () => void;
  authenticated: boolean;
  fetch: (input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>;
}

const defaultAuthContext: AuthContextValue = {
  username: '',
  logout: () => {},
  authenticated: false,
  fetch: auth.fetch.bind(auth),
};

export const AuthContext = createContext<AuthContextValue>(defaultAuthContext);

// type State = 'init' | 'pending' | 'successful' | 'error';

const AuthProvider: React.FC = ({ children }) => {
  const query = useQuery();
  const history = useHistory();
  const authorizationCode = query.get('code');
  const pathState = query.get('state') || encodeURIComponent(window.location.href.slice(window.location.origin.length));
  const [context, updateContext] = useState<AuthContextValue>(defaultAuthContext);

  const updateAuthState = (username: string) => {
    updateContext({
      username,
      authenticated: true,
      fetch: auth.fetch.bind(auth),
      logout: () => {
        auth
          .revokeTokens()
          .then(() => {
            window.location.replace(auth.getLogoutEndpoint());
          })
          .catch((e) => {
            console.log(e);
          });
      },
    });
  };

  useEffect(() => {
    if (authorizationCode === null) {
      window.location.replace(auth.getAuthEndpoint(pathState));
      return;
    } else {
      auth
        .getTokens(authorizationCode)
        .then((username) => {
          updateAuthState(username);
          history.push(decodeURIComponent(pathState));
        })
        .catch((e) => {
          throw e;
        });
    }
  }, []);

  if (context.authenticated) return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>;
  else return <AuthLoadingRoute />;
};

export default AuthProvider;
