import React from 'react';

export interface Props {
  type?: 'success' | 'warn' | 'danger' | 'default';
  title?: string;
  message: string | string[];
}

const Notice: React.FC<Props> = ({ type = 'default', title, message }) => {
  let bg = 'bg-uw-blue-800';
  let border = 'border-uw-blue-400';

  switch (type) {
    case 'success':
      bg = 'bg-green-800';
      border = 'border-green-600';
      break;

    case 'warn':
      bg = 'bg-yellow-700';
      border = 'border-yellow-500';
      break;

    case 'danger':
      bg = 'bg-red-900';
      border = 'border-red-600';
      break;
  }

  if (!Array.isArray(message)) {
    message = [message];
  }

  return (
    <div className={`rounded  border-l-4 shadow-md p-2 ${bg} ${border}`}>
      {title ? <p>{title}</p> : null}
      {message.map((line, index) =>
        line === '' ? (
          <br key={index} />
        ) : (
          <p className="block" key={index}>
            {line}
          </p>
        )
      )}
    </div>
  );
};

export default Notice;
