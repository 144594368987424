import React, { useContext, useState } from 'react';
import Button from '../components/button';
import DomainItem from '../components/domain-item';
import Notice, { Props as NoticeProps } from '../components/notice';
import Paper from '../components/paper';
import { AuthContext } from '../context/auth-provider';
import { DeleteListContext } from '../context/delete-list';
// import { NamesContext } from '../context/names';
import { NameItem } from '../types/certificate';

interface SuccessProps {
  names?: NameItem[];
}

const SuccessMessage: React.FC<SuccessProps> = ({ names }) => {
  return (
    <div className="space-items-y">
      <p className="font-bold text-xl mb-2">Your delete request has been sent!</p>

      {names ? (
        <>
          <p>The following names have been submitted to be removed:</p>
          <div className="bg-gray-800 px-4 rounded">
            {names.map((item) => (
              <DomainItem key={item.domain} item={item} actions={false} />
            ))}
          </div>
        </>
      ) : null}

      <p style={{ lineHeight: '2rem' }}>
        <span className="text-green-500 bg-gray-800 px-2 py-1 rounded">( Active )</span> domain names will take a while to rotate off of the
        certificates.
        <br />
        <span className="text-yellow-500 bg-gray-800 px-2 py-1 rounded">( Pending )</span> domain names should be removed soon.
        <br />
        Please do not try to re-delete any domain names.
      </p>

      <Button href="/">Return to homepage</Button>
    </div>
  );
};

const ErrorMessage: React.FC<{ message: string; extras?: React.ReactNode }> = ({ message, extras }) => {
  return (
    <div>
      {extras || null}
      <p>Error</p>
      <p>{message}</p>
    </div>
  );
};

const DeleteDomainsRoute: React.FC = () => {
  const deleteContext = useContext(DeleteListContext);
  //   const namesContext = useContext(NamesContext);
  const authContext = useContext(AuthContext);
  const [response, updateResponse] = useState<{ message: string | string[]; type?: NoticeProps['type'] } | null>(null);
  let domainText = 'domains';

  if (deleteContext.names.length === 1) {
    domainText = 'domain';
  }

  const onDelete = async () => {
    let message: string = `You are about to remove the following `;

    if (deleteContext.names.length === 1) {
      message += `${domainText}:\n\n`;
    } else {
      message += `${deleteContext.names.length} ${domainText}:\n\n`;
    }

    deleteContext.names.forEach((i) => {
      message += `${i.domain}\n`;
    });

    message += '\nPlease enter "confirm" into the text box to continue.';

    const confirm = window.prompt(message);

    if (confirm !== 'confirm' && confirm !== null) {
      window.alert(`The entered value was not "confirm", the domains will NOT be deleted.`);
    } else if (confirm === 'confirm') {
      try {
        const res = await authContext.fetch('/api/names', {
          method: 'delete',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            names: deleteContext.names.reduce<string[]>((acc, item) => {
              return [...acc, item.domain];
            }, []),
          }),
        });

        console.log(res);

        if (res.status === 202) {
          // updateResponse(<SuccessMessage names={deleteContext.names} />);

          updateResponse({
            type: 'success',
            message: [
              'The following names have been queued for removal:',
              ...deleteContext.names.reduce<string[]>((acc, n) => [...acc, n.domain], []),
              '',
              'Names may take a short time to be removed from active load balancers so changes might not be visible right away.',
            ],
          });
          deleteContext.clear();
        } else {
          updateResponse({
            type: 'danger',
            message: [
              'The delete request was not successful.',
              `Status code: ${res.status}.`,
              'The selected domains may have been deleted, please check the individual certificates to confirm their status.',
            ],
          });
        }
      } catch (e) {
        console.log(e);
        updateResponse({ type: 'danger', message: ['Error sending delete request.', 'Check your console for more information.'] });
      }
    }
  };

  // curl -H'Content-type: application/json' -d'{"names": ["jasontest2.webhosting.doit.wisc.edu"]}' -X DELETE https://certificate-shepherd-test.webhosting.doit.wisc.edu/api/names

  return (
    <div className="route">
      <Paper>
        <div className="pb-5 border-b border-gray-500">
          <p className="mb-2 font-bold">Domain removal queue</p>
          <p>The following domains are queued to be removed. Please review the list and ensure you have selected the correct domains.</p>
          <p>You will be prompted to confirm before removal happens.</p>
        </div>

        {deleteContext.names.length === 0 ? (
          <div className="text-center my-6">
            <p>No domains are in the delete queue.</p>
            <p>You can add domains from the domain search page.</p>
            <Button variant="contained" href="/names" className="mt-4">
              Search domains
            </Button>
          </div>
        ) : (
          deleteContext.names.map((item, index) => <DomainItem key={index} item={item} />)
        )}

        {deleteContext.names.length <= 0 ? null : (
          <div className="text-right mt-2 space-items-x">
            <Button variant="text" href="/names" className="mt-4">
              Search domains
            </Button>
            <Button variant="contained" color="danger" onClick={onDelete}>
              Remove selected {domainText}
            </Button>
          </div>
        )}

        {response !== null ? (
          <div className="mt-5">
            <Notice type={response.type} message={response.message} />
          </div>
        ) : null}
      </Paper>
    </div>
  );
};

export default DeleteDomainsRoute;
