import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  variant?: 'outlined' | 'contained' | 'text';
  href?: string;
  width?: number | string;
  color?: 'default' | 'primary' | 'secondary' | 'danger' | 'warning' | 'success';
  target?: string;
  clickMessage?: string;
  clickMessageDuration?: number;
  type?: 'button' | 'submit';
  disabled?: boolean;
}

const Button: React.FC<Props & React.HtmlHTMLAttributes<HTMLAnchorElement | HTMLButtonElement>> = ({
  children,
  className = '',
  variant = 'outlined',
  color = 'default',
  type = 'button',
  href,
  onClick,
  width = 'auto',
  clickMessageDuration = 1500,
  clickMessage,
  target,
  disabled = false,
}) => {
  const [clickState, updateClickState] = useState(false);
  let classes = `cert-shepherd-button ${className} inline-block text-gray-100 rounded-md px-4 py-1.5 ${disabled === true ? 'disabled' : ''}`;

  const clickHandler = (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    if (onClick) onClick(e);
    if (clickMessage) {
      updateClickState(true);

      setTimeout(() => {
        updateClickState(false);
      }, clickMessageDuration);
    }
  };

  switch (variant) {
    case 'outlined':
      classes += ` border border-opacity-50 hover:border-opacity-100 hover:bg-opacity-20`;

      switch (color) {
        case 'danger':
          classes += ' border-red-500 hover:bg-red-500 text-red-500';
          break;
        default:
          classes += ' border-white hover:bg-white';
          break;
      }

      break;

    case 'contained':
      classes += ' shadow-md';

      switch (color) {
        case 'danger':
          classes += ' bg-red-800 hover:bg-red-900 ';
          break;
        default:
          classes += ' bg-uw-blue-800 hover:bg-uw-blue-900 ';
          break;
      }
      break;

    case 'text':
      classes += ' hover:bg-white hover:bg-opacity-20';
      break;
  }

  if (href) {
    if (href.startsWith('/')) {
      return (
        <Link to={href} className={classes} style={{ width }} onClick={clickHandler} target={target}>
          {clickState ? clickMessage : children}
        </Link>
      );
    } else {
      return (
        <a href={href} className={classes} style={{ width }} target={target}>
          {children}
        </a>
      );
    }
  } else {
    return (
      <button className={classes} onClick={clickHandler} style={{ width }} type={type}>
        {clickState ? clickMessage : children}
      </button>
    );
  }
};

export default Button;
