import React from 'react'
import AppBar from '../components/app-bar';
import Button from '../components/button';
import Paper from '../components/paper';


const LogoutRoute: React.FC = () => {
    return (
        <>
            <AppBar />
            <div className="route">
                <Paper className="py-20 text-center">
                    <p className="text-lg mb-5">You have been logged out!</p>
                    <Button href="/" variant="contained">Log back in</Button>
                </Paper>
            </div>
        </>
    );
}

export default LogoutRoute;