import React from 'react';
import AppBar from '../components/app-bar';
import LoadingPlaceholder from '../components/loading-placeholder';
import Paper from '../components/paper';

const AuthLoadingRoute: React.FC = () => {
  return (
    <>
      <AppBar />
      <div className="route space-items-y-lg">
        <Paper>
          <div className="space-items-y">
            <LoadingPlaceholder height={24} width={135} />
            <LoadingPlaceholder height={62} />
            <div className="text-right">
              <LoadingPlaceholder className="inline-block" height={36} width={81} />
            </div>
          </div>
        </Paper>

        <Paper style={{ minHeight: 130 }} />
      </div>
    </>
  );
};

export default AuthLoadingRoute;
