import React, { useContext } from 'react';
import { DeleteListContext } from '../context/delete-list';
import Button from './button';
import SelectOnClick from './select-on-click';
import { NameItem } from '../types/certificate';

interface Props {
  item: NameItem;
  actions?: boolean;
}

const DomainItem: React.FC<Props> = ({ item, actions = true }) => {
  const { domain, arn } = item;
  const deleteContext = useContext(DeleteListContext);
  const inDeleteList = deleteContext.names.find((i) => i.domain === domain) !== undefined;

  const onDelete = () => {
    if (deleteContext.names.find((i) => i.domain === domain)) {
      deleteContext.remove(domain);
    } else {
      deleteContext.add(domain, arn);
    }
  };

  return (
    <li className="domain-search-result flex items-center justify-between border-b border-gray-500 last:border-b-0 py-2" tabIndex={0}>
      <div>
        <div className="flex items-center">
          <SelectOnClick text={domain} className={inDeleteList ? 'text-red-500' : ''} />
        </div>
        <SelectOnClick text={arn} variant="subtitle" />
      </div>

      {actions ? (
        <div className="space-items-x domain-search-result-actions">
          <Button variant="text" href={`/certificates/${arn}`}>
            View certificate
          </Button>
          <Button
            color={inDeleteList ? 'danger' : 'default'}
            onClick={(e) => {
              e.preventDefault();
              onDelete();
            }}
          >
            {inDeleteList ? 'Dequeue removal' : 'Queue removal'}
          </Button>
        </div>
      ) : null}
    </li>
  );
};

export default DomainItem;
