import React from 'react';
import { Link } from 'react-router-dom';
import SheepIcon from './sheep-icon';

const AppBar: React.FC = ({ children, }) => {

    return (
        <div className="app-bar bg-red-700 shadow-md sticky flex justify-between items-center px-8 top-0 z-50	" style={{ height: 50 }}>
            <Link className="flex items-center" to="/">
                <SheepIcon size={40} />
                <p className="text-xl ml-5">Certificate Shepherd</p>
            </Link>

            {children ? (
                <div className="flex items-center">{children}</div>
            ) : null}
        </div>
    );
};

export default AppBar;
