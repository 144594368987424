// Libs
import React from 'react';

// Components
import Paper from '../components/paper';

const CertificatesRoute: React.FC = () => {
  return (
    <div className="route">
      <Paper>
        <p className="text-xl text-bold mb-4">All certificates</p>
        <p>TODO</p>
      </Paper>
    </div>
  );
};

export default CertificatesRoute;
