import React from "react";

interface Props {
    rows: [string, string | number][]
}

const JustifyTable: React.FC<Props> = ({ rows }) => {
    return (
        <table className="m-auto">
            <tbody>
                {rows.map((row, index) => (
                    <tr key={index}>
                        <td className="text-right font-bold pr-5 w-3/6">{row[0]}</td>
                        <td className="text-left w-3/6">{row[1]}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}


export default JustifyTable;