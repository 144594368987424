import React, { useContext } from 'react';
// import { NamesContext } from '../context/names';
import { GroupedPendingValidation, PendingCertificate } from '../types/certificate';
import SelectOnClick from './select-on-click';

interface GroupedNames {
  [key: string]: PendingCertificate[];
}

interface Props {
  dcvs: GroupedPendingValidation[];
}

const ListDCVs: React.FC<Props> = ({ dcvs }) => {
  if (dcvs.length === 0) {
    return (
      <>
        <p className="pb-2 mb-2 border-b border-gray-500">Pending DCV Values</p>
        <p className="text-center my-10">No DCV's are pending at this time!</p>
      </>
    );
  }

  return (
    <>
      <p className="pb-2 mb-2 border-b border-gray-500">Pending DCV Values</p>
      <div className="space-items-y">
        {dcvs
          .sort((a, b) => {
            // Push automated DCV's to the bottom
            if (a.automated && !b.automated) return 1;
            else if (!a.automated && b.automated) return -1;
            // Alphabetize based on hostmaster
            else if (a.hostmaster > b.hostmaster) return 1;
            else if (a.hostmaster < b.hostmaster) return -1;
            return 0;
          })
          .map((group) => {
            return (
              <div key={group.hostmaster}>
                <p className="text-sm font-mono mb-2">
                  {group.hostmaster}
                  {group.automated ? <span className="ml-2">(Automated)</span> : null}
                </p>
                <SelectOnClick
                  variant="pre"
                  text={group.dcvs.reduce<string>((acc, cert, index) => {
                    acc += `${cert.Name} CNAME ${cert.Value}`;
                    if (index + 1 !== group.dcvs.length) acc += `\n`;
                    return acc;
                  }, '')}
                />
              </div>
            );
          })}
      </div>
    </>
  );
};

export default ListDCVs;
