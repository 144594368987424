// Libs
import React, { useContext, useState } from 'react';

// Components
import { useHistory } from 'react-router-dom';
import Button from '../components/button';
import TextInput from '../components/input';
import Paper from '../components/paper';
import { AuthContext } from '../context/auth-provider';

const HomeRoute: React.FC = () => {
  const history = useHistory();
  const authContext = useContext(AuthContext);
  const [query, updateQuery] = useState<string>('');

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    history.push(`/names?q=${encodeURIComponent(query)}`);
  };

  const clearCache = async () => {
    try {
      const res = await authContext.fetch('/api/cache', {
        method: 'DELETE',
      });

      console.log(res);

      if (res.ok) {
        const json = await res.json();

        console.log(json);

        alert('The cache has been cleared!');
      } else {
        alert(`The cache could not be cleared. The server responded with a status of ${res.status}. Check the developer console for more info.`);
      }
    } catch (e) {
      console.log(e);
      alert('Error clearing the application cache. The request could not be sent.');
    }
  };

  return (
    <div className="route space-items-y-lg">
      <Paper>
        <div className="space-items-y">
          <p>Domain search</p>
          <form onSubmit={onSubmit} className="space-items-y">
            <TextInput
              autoFocus
              id="home-new-domain-input"
              label="Domain / ARN"
              value={query}
              variant="contained"
              onChange={(e) => {
                e.preventDefault();
                updateQuery((e.target as HTMLInputElement).value);
              }}
            />

            <div className="text-right">
              <Button variant="contained" type="submit">
                Search
              </Button>
            </div>
          </form>
        </div>
      </Paper>

      <Paper>
        {/* <ListDCVs /> */}
        <p className="mb-5 text-gray-400">Utilities</p>
        <div className="space-items-x">
          <Button
            onClick={() => {
              const didConfirm = window.confirm('Refreshing the application cache is an expensive operation, are you sure you want to do this?');

              if (!didConfirm) {
                return;
              }

              clearCache();
            }}
          >
            Refresh application cache
          </Button>

          <Button href="/dcvs">View all DCV's</Button>
        </div>
      </Paper>
    </div>
  );
};

export default HomeRoute;
