// Libs
import React, { useContext } from 'react';

// Context
import AuthProvider, { AuthContext } from './context/auth-provider';

// Components
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import AppBar from './components/app-bar';
import Button from './components/button';

// Routes
import HomeRoute from './routes/home';
import DomainSearchRoute from './routes/domain-search';
import CertificatesRoute from './routes/certificates';
import CertificateRoute from './routes/certificate';
import LogoutRoute from './routes/logout';
import DeleteListContextProvider, { DeleteListContext } from './context/delete-list';
import DeleteDomainsRoute from './routes/delete';
import HelpRoute from './routes/help';
import AuthLoadingRoute from './routes/auth-loading';
import DCVListRoute from './routes/dcvs';

const AuthenticatedRoutes: React.FC = () => {
  const authContext = useContext(AuthContext);
  const deleteContext = useContext(DeleteListContext);

  return (
    <>
      <AppBar>
        <div className="flex items-center space-items-x">
          {deleteContext.names.length > 0 ? <Button href="/delete">Domain removal list ({deleteContext.names.length})</Button> : null}
          {/* <Button onClick={namesContext.refresh}>{namesContext.loading ? 'Refreshing domains' : 'Refresh domains'}</Button> */}
          <Button onClick={authContext.logout}>Log out</Button>
        </div>
      </AppBar>
      <div>
        <Route path="/" exact>
          <HomeRoute />
        </Route>

        {/* <Route path="/help" exact>
          <HelpRoute />
        </Route> */}

        <Route path="/dcvs" exact>
          <DCVListRoute />
        </Route>

        <Route path="/delete" exact>
          <DeleteDomainsRoute />
        </Route>

        <Route path="/names">
          <DomainSearchRoute />
        </Route>

        <Route path="/certificates" exact>
          <CertificatesRoute />
        </Route>

        <Route path="/certificates/:arn/:id">
          {/* JSON route is nested in this component */}
          <CertificateRoute />
        </Route>
      </div>
    </>
  );
};

const RouterComponent: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        {/* Unauthenticated routes */}
        <Route path="/logout" exact>
          <LogoutRoute />
        </Route>

        {/* Authenticated routes */}
        <Route>
          <AuthProvider>
            <DeleteListContextProvider>
              <AuthenticatedRoutes />
            </DeleteListContextProvider>
          </AuthProvider>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default RouterComponent;
