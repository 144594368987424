import React, { useContext, useEffect, useState } from 'react';
import ListDCVs from '../components/dcv-list';
import LoadingDots from '../components/loading-dots';
import Notice, { Props as NoticeProps } from '../components/notice';
import Paper from '../components/paper';
import { AuthContext } from '../context/auth-provider';
import { GroupedPendingValidation } from '../types/certificate';

const DCVListRoute: React.FC = () => {
  const [error, updateError] = useState<null | NoticeProps>(null);
  const [state, updateState] = useState<null | GroupedPendingValidation[]>(null);
  const authContext = useContext(AuthContext);

  const getDCVs = async () => {
    try {
      const res = await authContext.fetch('/api/dcvs');

      if (res.ok) {
        const json = (await res.json()) as { GroupedPendingValidation: GroupedPendingValidation[] };
        updateState(json.GroupedPendingValidation);
      } else {
        console.log(res);
        updateError({
          type: 'danger',
          message: [
            "Error fetching DCV's.",
            `Server responded with a status of ${res.status}`,
            'Check the developer console for the full response body.',
          ],
        });
      }
    } catch (e) {
      console.log(e);

      updateError({
        type: 'danger',
        message: [
          "Error fetching DCV's.",
          'The request to the API failed before it could reach the server.',
          'Check the developer console for error logs.',
        ],
      });
    }
  };

  useEffect(() => {
    getDCVs();
  }, []);

  if (error !== null) {
    return (
      <div className="route">
        <Paper>
          <Notice {...error} />
        </Paper>
      </div>
    );
  }

  if (state === null) {
    return (
      <div className="route">
        <Paper className="text-center p-20">
          <LoadingDots />
          <p className="opacity-50 mt-5 text-xl">Loading DCV's, hang tight.</p>
        </Paper>
      </div>
    );
  }

  return (
    <div className="route">
      <Paper>
        <ListDCVs dcvs={state} />
      </Paper>
    </div>
  );
};

export default DCVListRoute;
