import React from 'react';

interface Props {
  rounded?: boolean;
  padded?: boolean;
}

const Paper: React.FC<Props & React.HtmlHTMLAttributes<HTMLDivElement>> = ({ children, style, rounded = true, padded = true, className = '' }) => {
  return (
    <div style={style} className={`bg-gray-700 shadow-lg ${padded ? 'p-5' : ''} ${rounded ? 'rounded' : ''} ${className}`}>
      {children}
    </div>
  );
};

export default Paper;
