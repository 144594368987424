import React from 'react';

interface Props {
  label: string;
  id: string;
  value: string;
  type?: string;
  variant?: 'outlined' | 'contained';
  autoFocus?: boolean;
}

const TextInput: React.FC<Props & React.HtmlHTMLAttributes<HTMLInputElement>> = ({
  label,
  className = '',
  placeholder,
  type = 'text',
  id,
  value,
  onChange,
  variant = 'outlined',
  autoFocus,
}) => {
  switch (variant) {
    case 'outlined':
      return (
        <div className={`input input-outlined relative border border-gray-500 rounded-md focus-within:border-uw-blue-700 ${className}`}>
          <label htmlFor={id} className="bg-gray-700 absolute px-2 text-sm text-gray-500" style={{ top: -12, left: 8 }}>
            {label}
          </label>
          <input
            id={id}
            type={type}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            autoComplete="off"
            className="bg-transparent px-4 py-3 w-full outline-none"
          />
        </div>
      );

    case 'contained':
      return (
        <div className={`input input-contained relative bg-gray-600 rounded-md ${className}`}>
          <label htmlFor={id} className="absolute px-2 text-sm text-gray-400" style={{ top: 5, left: 8 }}>
            {label}
          </label>
          <input
            id={id}
            type={type}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            autoComplete="off"
            autoFocus={autoFocus}
            className="bg-transparent px-4 py-2 pt-7 w-full outline-none"
          />
        </div>
      );
  }
};

export default TextInput;
