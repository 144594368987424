import jwkToPem from 'jwk-to-pem';
import jwt from 'jsonwebtoken';

interface RawTokens {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token: string;
}

interface TokenHeaders {
  kid: string;
  alg: string;
}

interface IDTokenPayload {
  at_hash: string;
  aud: string;
  auth_time: number;
  'cognito:groups': string[];
  'cognito:username': string;
  email: string;
  email_verified: boolean;
  exp: number;
  iat: number;
  identities: unknown[];
  iss: string;
  jti: string;
  nickname: string;
  origin_jti: string;
  sub: string;
}

interface ValidTokenPayload {
  id: IDTokenPayload;
  jwt: string;
  refresh: string;
  expires: number;
  created: number;
}

interface JSONWebKey {
  kid: string;
  alg: string;
  kty: string;
  e: string;
  n: string;
  use: string;
}

interface JSONWebKeys {
  keys: JSONWebKey[];
}

interface CookieTokens {
  username: string;
  jwt: string;
  refresh: string;
  expires: number;
  created: number;
}

class AuthHelper {
  // Constants
  private IDP_ENDPOINT = (window as any).CERTIFICATE_SHEPHERD_CONFIG.IDP_ENDPOINT;
  private API_ENDPOINT = (window as any).CERTIFICATE_SHEPHERD_CONFIG.API_ENDPOINT;
  private AUTH_ENDPOINT = (window as any).CERTIFICATE_SHEPHERD_CONFIG.AUTH_ENDPOINT;
  private CLIENT_ID = (window as any).CERTIFICATE_SHEPHERD_CONFIG.CLIENT_ID;
  private AUTH_REDIRECT_URI: string;
  private AUTH_LOGOUT_URI: string;

  constructor() {
    const { origin } = window.location;
    const redirectURIs: string[] = (window as any).CERTIFICATE_SHEPHERD_CONFIG.AUTH_REDIRECT_URI;
    const logoutURIs: string[] = (window as any).CERTIFICATE_SHEPHERD_CONFIG.AUTH_LOGOUT_URI;

    if (redirectURIs.length > 1) {
      this.AUTH_REDIRECT_URI = redirectURIs.find((url) => url.startsWith(origin)) || '';
    } else {
      this.AUTH_REDIRECT_URI = redirectURIs[0];
    }

    if (logoutURIs.length > 1) {
      this.AUTH_LOGOUT_URI = logoutURIs.find((url) => url.startsWith(origin)) || '';
    } else {
      this.AUTH_LOGOUT_URI = logoutURIs[0];
    }
  }

  // Auth information
  private jwt?: string;
  private id?: IDTokenPayload;
  private refresh?: string;
  private authTime?: number;
  private expires?: number;

  public getAuthEndpoint(state?: string) {
    return `${this.AUTH_ENDPOINT}/oauth2/authorize?response_type=code&client_id=${this.CLIENT_ID}&state=${
      state || window.location.pathname
    }&redirect_uri=${this.AUTH_REDIRECT_URI}`;
  }

  public getLogoutEndpoint() {
    return `${this.AUTH_ENDPOINT}/logout?client_id=${this.CLIENT_ID}&logout_uri=${this.AUTH_LOGOUT_URI}`;
  }

  public async refreshTokens() {
    if (!this.refresh) {
      throw 'Error: No active refresh token.';
    }
    try {
      const res = await fetch(`${this.AUTH_ENDPOINT}/oauth2/token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
          grant_type: 'refresh_token',
          client_id: this.CLIENT_ID,
          refresh_token: this.refresh,
        }),
      });

      if (!res.ok) {
        throw 'Error fetching refresh token.';
      }

      const rawTokens = (await res.json()) as RawTokens;

      await this.validateTokens({ ...rawTokens, refresh_token: this.refresh });
    } catch (e) {
      console.log(e);
      return e;
    }
  }

  public async getTokens(authorizationCode: string): Promise<string> {
    try {
      const res = await fetch(`${this.AUTH_ENDPOINT}/oauth2/token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
          grant_type: 'authorization_code',
          code: authorizationCode,
          client_id: this.CLIENT_ID,
          redirect_uri: this.AUTH_REDIRECT_URI,
        }),
      });

      if (!res.ok) {
        throw 'Error fetching tokens with the provided authorization code.';
      }

      const tokens = (await res.json()) as RawTokens;
      const validatedTokens = await this.validateTokens(tokens);

      console.log(validatedTokens);

      return validatedTokens.id.nickname;
    } catch (e) {
      console.log(e);
      // @ts-ignore
      return e;
    }
  }

  /**
   * Validate the autenticity of the tokens returned from AWS Cognito.
   * https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-tokens-verifying-a-jwt.html
   * @param tokens The token object returned from the cognito oauth2/tokens endpoint
   * @returns
   */
  private async validateTokens(tokens: RawTokens): Promise<ValidTokenPayload> {
    const splitIdToken = tokens.id_token.split('.');

    if (splitIdToken.length !== 3) {
      throw 'Invalid Cognito JWT token format';
    }

    const idTokenHeaders = JSON.parse(Buffer.from(splitIdToken[0], 'base64').toString('utf8')) as TokenHeaders;
    const idTokenPayload = JSON.parse(Buffer.from(splitIdToken[1], 'base64').toString('utf8')) as IDTokenPayload;

    try {
      const keys = await this.getJsonWebKeys();

      const key = (keys as JSONWebKeys).keys.find((k) => k.kid === idTokenHeaders.kid);

      if (!key) {
        throw 'Could not find public key matching the provided token.';
      }

      // @ts-ignore
      const pem = jwkToPem(key);

      const isValid = await this.jwkVerify(tokens.id_token, pem);

      if (isValid === false) {
        throw 'Could not validate tokens';
      }

      this.id = idTokenPayload;
      this.refresh = tokens.refresh_token;
      this.jwt = tokens.id_token;
      this.expires = idTokenPayload.exp * 1000;
      this.authTime = idTokenPayload.auth_time * 1000;

      return {
        id: idTokenPayload,
        refresh: tokens.refresh_token,
        jwt: tokens.id_token,
        // Unix timestamp in seconds, need to convert to milliseconds to better work with JS Date obejcts
        expires: idTokenPayload.exp * 1000,
        created: idTokenPayload.auth_time * 1000,
      };
    } catch (e) {
      console.log(e);
      // @ts-ignore
      return e;
    }
  }

  private jwkVerify(id: string, pem: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      jwt.verify(id, pem, { algorithms: ['RS256'] }, function (err, decodedToken) {
        if (err) {
          reject({ error: err });
          return;
        }

        resolve(true);
      });
    });
  }

  private async getJsonWebKeys(): Promise<JSONWebKeys> {
    try {
      const res = await fetch(`${this.IDP_ENDPOINT}/.well-known/jwks.json`);

      if (!res.ok) {
        throw 'Could not fetch public keys.';
      }

      return (await res.json()) as JSONWebKeys;
    } catch (e) {
      console.log(e);
      // @ts-ignore
      return e;
    }
  }

  public async revokeTokens(): Promise<boolean> {
    // If no refresh token is set, exit
    if (!this.refresh) return false;

    try {
      const res = await fetch(`${this.AUTH_ENDPOINT}/oauth2/revoke?client_id=${this.CLIENT_ID}&token=${btoa(this.refresh)}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (!res.ok) {
        throw 'Error revoking tokens';
      }

      return true;
    } catch (e) {
      console.log(e);
      // @ts-ignore
      return e;
    }
  }

  public async fetch(input: RequestInfo, init?: RequestInit | undefined): Promise<Response> {
    try {
      if (this.expires && Date.now() > this.expires) {
        await this.refreshTokens();
        console.log('refreshed');
      }

      // Add Authorization headers
      if (!init) {
        init = {
          headers: { Authorization: this.jwt || '' },
        };
      } else if (init.headers) {
        init.headers = { ...init.headers, Authorization: this.jwt || '' };
      } else {
        init.headers = { Authorization: this.jwt || '' };
      }

      //   Fail override for testing

      //   if (input.toString().startsWith('/api/dcvs')) {
      //     return fetch(`${this.API_ENDPOINT}/api/fail?status=404`, init);
      //   }

      return fetch(`${this.API_ENDPOINT}${input.toString()}`, init);
    } catch (e) {
      console.log(e);
      // @ts-ignore
      return e;
    }
  }
}

const auth = new AuthHelper();
export default auth;
