// Libraries
import React, { useContext } from 'react';
import { withRouter, RouteComponentProps, Route, useLocation } from 'react-router-dom';

// Components
import Paper from '../components/paper';
import JustifyTable from '../components/justify-table';
import Button from '../components/button';
import SelectOnClick from '../components/select-on-click';
import { useState } from 'react';
import { Certificate } from '../types/certificate';
import { useEffect } from 'react';
import LoadingDots from '../components/loading-dots';
import CertificateJSONRoute from './certificate-json';
import { AuthContext } from '../context/auth-provider';
import Notice, { Props as NoticeProps } from '../components/notice';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

// URL variables
interface Params {
  arn: string;
  id: string;
}

const CertificateRoute: React.FC<RouteComponentProps<Params>> = ({ match }) => {
  const query = useQuery();
  const q = query.get('q');
  // Get the ARN from the URL
  const certID = match.params.id;
  const certRegion = match.params.arn.split(':')[3];
  const arn = `${match.params.arn}/${match.params.id}`;
  const authContext = useContext(AuthContext);
  const [cert, updateCert] = useState<null | Certificate>(null);
  const [error, updateError] = useState<null | NoticeProps>(null);

  const getCertificate = async () => {
    const res = await authContext.fetch(`/api/certificate-request/${arn}`);

    if (res.ok) {
      const json = (await res.json()) as Certificate;
      updateCert(json);
    } else {
      console.log('Error getting certificate');
      console.log(res);
      updateError({
        type: 'danger',
        message: [
          'Could not get certificate data.',
          `The API responeded with a status code of ${res.status}`,
          '',
          'Check the developer consoles for more details.',
        ],
      });
    }
  };

  useEffect(() => {
    getCertificate();
  }, []);

  if (error !== null) {
    return (
      <div className="route">
        <Paper>
          <Notice {...error} />

          <div className="mt-5">
            <Button href={q !== null ? `/names?q=${q}` : '/names'} variant="text">
              Return to search
            </Button>
          </div>
        </Paper>
      </div>
    );
  }

  if (cert === null) {
    return (
      <div className="route">
        <Paper className="text-center p-20">
          <LoadingDots />
          <p className="opacity-50 mt-5 text-xl">Loading certificate, hang tight.</p>
        </Paper>
      </div>
    );
  }

  const stage = cert.Tags?.find((t) => t.Key === 'stage')?.Value || 'n/a';
  const env = cert.Tags?.find((t) => t.Key === 'env')?.Value || 'n/a';
  const hostmaster = cert.Tags?.find((t) => t.Key === 'validation-rname')?.Value || 'n/a';

  const rows: [string, string | number][] = [
    ['Workflow stage', stage],
    ['Environment', env],
    ['Hostmaster', hostmaster],
    ['In use by', cert.InUseBy?.length || 'n/a'],
    ['Subject alternative names', cert.SubjectAlternativeNames?.length || 'n/a'],
  ];

  return (
    <>
      <Route path="/certificates/:arn/:id" exact>
        <div className="route">
          <Paper>
            <div>
              <p className="font-bold text-lg">
                {cert.DomainName} - <span>{stage}</span>
              </p>
              <SelectOnClick text={cert.CertificateArn} />

              {/* <pre>{JSON.stringify(cert, null, 4)}</pre> */}
            </div>

            <div className="my-10">
              <JustifyTable rows={rows} />
            </div>

            <div className="my-10">{/* <JustifyTable rows={cert.Tags.red} /> */}</div>

            <div className="flex justify-between">
              <div>
                <Button href={q !== null ? `/names?q=${q}` : '/names'} variant="text">
                  Return to search
                </Button>
              </div>

              <div className="space-items-x text-right">
                <Button
                  href={`https://${certRegion}.console.aws.amazon.com/acm/home?region=${certRegion}#/?id=${certID}`}
                  variant="text"
                  target="_blank"
                >
                  Open in AWS
                </Button>

                <Button href={`/certificates/${arn}/json${q !== null ? '?q=' + q : ''}`} variant="text">
                  View certificate JSON
                </Button>
              </div>
            </div>
          </Paper>
        </div>
      </Route>

      <Route path="/certificates/:arn/:id/json" exact>
        <CertificateJSONRoute certificate={cert} />
      </Route>
    </>
  );
};

export default withRouter(CertificateRoute);
