import React from 'react';

import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import jsonLang from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import dark from '../syntax-styles';
import Button from '../components/button';
import SelectOnClick from '../components/select-on-click';
import Paper from '../components/paper';
import SheepIcon from '../components/sheep-icon';
import { Certificate } from '../types/certificate';
import { useLocation } from 'react-router-dom';

SyntaxHighlighter.registerLanguage('json', jsonLang);

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

// URL variables
interface Props {
  certificate: null | Certificate;
}

const CertificateJSONRoute: React.FC<Props> = ({ certificate }) => {
  const query = useQuery();
  const q = query.get('q');

  if (!certificate)
    return (
      <div className="route">
        <Paper className="text-center">
          <div className="flex align-middle justify-center mb-5">
            <SheepIcon size={100} />
          </div>
          <p className="font-bold mb-10 text-3xl">No certificate found</p>
          <Button href="/">Return to main page</Button>
        </Paper>
      </div>
    );

  return (
    <>
      <div style={{ marginBottom: 78 }}>
        <SyntaxHighlighter className="cert-json" language="json" style={dark} showLineNumbers>
          {JSON.stringify(certificate, null, 4)}
        </SyntaxHighlighter>
      </div>

      <div
        className="app-bar flex items-center justify-between px-8 w-full fixed border-t border-opacity-50 border-color-white"
        style={{ bottom: 0, height: 75, backgroundColor: '#272c35' }}
      >
        <div className="flex flex-col justify-center">
          <p className="font-bold block">{certificate.DomainName}</p>
          <SelectOnClick text={certificate.CertificateArn} />
        </div>

        <div className="space-items-x">
          <Button href={q !== null ? `/names?q=${q}` : '/names'} variant="text">
            Return to search
          </Button>
          <Button
            clickMessage="Copied"
            variant="text"
            width={116}
            onClick={(e) => {
              e.preventDefault();
              e.preventDefault();
              navigator.clipboard.writeText(JSON.stringify(certificate, null, 4));
            }}
          >
            Copy JSON
          </Button>
          <Button href={`/certificates/${certificate.CertificateArn}${q !== null ? '?search=' + q : ''}`}>Exit JSON view</Button>
        </div>
      </div>
    </>
  );
};

export default CertificateJSONRoute;
