import React, { MouseEvent } from 'react';

interface Props {
  text: string;
  variant?: 'subtitle' | 'pre';
}

const SelectOnClick: React.FC<Props & React.HTMLAttributes<HTMLParagraphElement>> = ({ text, variant, className = '' }) => {
  const onClick = (e: MouseEvent<HTMLParagraphElement | HTMLPreElement>) => {
    e.preventDefault();
    const range = document.createRange();
    const selection = window.getSelection();

    range.selectNodeContents(e.currentTarget);

    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
      //   navigator.clipboard.writeText(arn);
    }
  };

  switch (variant) {
    case 'pre':
      return (
        <pre className={`opacity-50 text-sm font-mono bg-gray-800 p-5 rounded overflow-x-auto ${className}`} onClick={onClick}>
          {text}
        </pre>
      );

    case 'subtitle':
      return (
        <p className={`opacity-50 text-sm ${className}`} onClick={onClick}>
          {text}
        </p>
      );

    default:
      return (
        <p className={className} onClick={onClick}>
          {text}
        </p>
      );
  }
};

export default SelectOnClick;
