import React from 'react';

interface Props {
  height?: number;
  width?: number;
  className?: string;
  opacity?: number;
}

const LoadingPlaceholder: React.FC<Props> = ({ height, width, className, opacity }) => {
  return <div className={`bg-gray-600 rounded ${className}`} style={{ height, width: width || '100%', opacity }} />;
};

export default LoadingPlaceholder;
